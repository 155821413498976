import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(
    private http: HttpClient
  ) { }

  integrations: any[] = [];

  getIntegrations() :Observable<any> {
    return this.http.get<any>('/integration/', {observe: 'response'});
  }

  getGhlCalendars() :Observable<any> {
    return this.http.get<any>('/calendar/ghl', {observe: 'response'});
  }

  getGoogleCalendars() :Observable<any> {
    return this.http.get<any>('/calendar/google', {observe: 'response'});
  }

  getCalCalendars() :Observable<any> {
    return this.http.get<any>('/calendar/cal', {observe: 'response'});
  }

  connectCal(obj: any) :Observable<any> {
    return this.http.post<any>('/integration/cal', obj, { observe: 'response' }); 
  }
}
