export const environment = {
  production: false,
  RECAPTCHA_V3_SITE_KEY: '6LcQG5IhAAAAAGBXEYKwSWAFy6Obcu44qYRm2t1l',
  dialog360ID: '805HUxPA',
  firebase: {
    apiKey: "AIzaSyB76kOK6DIoik5N8H6W2SzPMB95YwHObpk",
    authDomain: "sobot-d9428.firebaseapp.com",
    projectId: "sobot-d9428",
    storageBucket: "sobot-d9428.appspot.com",
    messagingSenderId: "878353671320",
    appId: "1:878353671320:web:d9fbc24327110d7b441ced",
    measurementId: "G-MCYGWM2295",
  },
  GOOGLE_PROVIDER_ID: "624413500666-3ehqfim7tokdl09c8fjanvp99n1pqfcj.apps.googleusercontent.com",
  FCM_VapidKey: "BEqZwDpYTjJrra8-s20DD1jTvVj2wfX21xU66AJ6iITZ1R0fZCArycCn3TCxU3GCGWOim-esCCvOVgT34gpw6qM",
  posthog: 'phc_zOgSmPaGZu1xAp3L3yogLlQaFjYt7YLS1MGKZJAhre6',
  API_URL: 'dev-api.oneinbox.ai',
  // API_URL: 'evolved-powerful-tick.ngrok-free.app',
  // API_URL: '004e-27-7-150-165.ngrok-free.app',
  UI_BASE_URL: 'dev.oneinbox.ai',
  https: true,
};
