import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
})

export class DatePickerComponent implements OnInit {

  @Input() date!: Date | null;
  @Output() dateChange = new EventEmitter<Date>();
  @Input() startDate!: Date;
  @Input() endDate!: Date;
  dateString!: Date;

  @ViewChild('picker') picker!: MatDatepicker<Date>;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'calender_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/calender_grey.svg'
      )
    );
  }

  ngOnInit(): void {
    this.picker.open();
  }

  onDateChange(event:any) {
    this.date = event.value;
    if(this.date) this.dateChange.emit(this.date);
  }

  getDate(event:any) {
    this.date = this.dateString;
  }
}