import { Component, EventEmitter, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { IntegrationsService } from 'src/app/services/integrations.service';

@Component({
  selector: 'cal-connection',
  templateUrl: './cal-connection.component.html',
  styleUrl: './cal-connection.component.css'
})
export class CalConnectionComponent {

  @Output() close = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter<boolean>();
  isOpen: boolean = true;
  isLoading: boolean = false;
  apiKey: string = '';

  constructor(
    private _integrationService: IntegrationsService,
    private _commonService: CommonService,
  ) {
    
  }

  connect() {
    this.isLoading = true;
    let input = {
      secret: this.apiKey
    }
    this._integrationService.connectCal(input).subscribe({
      next: resp => {
        this._commonService.getIntegrations();
        this.onSuccess.emit(true);
        this.isLoading = false;
      }
    })
  }
}
